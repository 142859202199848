.table-container {
  position: absolute;
  width: 600px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100vh;
  overflow: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  }
  
  .custom-table {
    width: 100%;
    height: 400px;
    border: 1px solid white;
    border-radius: 5px;
    overflow: auto;
    overflow: hidden;
    font-family: 'RobotoMonoThin', monospace;
  }
  
  .table-header {
    display: flex;
    background-color: #333;
    color: #fff;
    text-align: center;
  }

  .table-body{
    height: 360px;
    overflow: auto;
  }
  
  .table-cell {
    flex: 1;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  
  .table-row {
    display: flex;
    border-top: 1px solid white;
  }
  
  .table-row:hover {
    background-color: #555;
    cursor: pointer;
  }

  .green-bg {
    background-color: green;
    transition: background-color 2s ease;
  }
  
  