.App {
    position: relative;
    height: 100vh;
  }
  
  .main-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
  }
  
  /* .main-heading {
    font-size: 175px;
    text-transform: uppercase;
    margin: -30px;
  } */
  

  .sub-heading {
    font-size: 33px;
    font-family: 'RobotoMonoThin', monospace;
  }


  .text-3d {
    margin: -15px;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 175px;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191,
        1px 2px 1px #919191,
        1px 3px 1px #919191,
        1px 4px 1px #919191,
        1px 5px 1px #919191,
        1px 6px 1px #919191,
        1px 7px 1px #919191,
        1px 8px 1px #919191,
        1px 9px 1px #919191,
        1px 10px 1px #919191,
    1px 18px 6px rgba(16,16,16,0.4),
    1px 22px 10px rgba(16,16,16,0.2),
    1px 25px 35px rgba(16,16,16,0.2),
    1px 30px 60px rgba(16,16,16,0.4);
}
  