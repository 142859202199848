/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    transition: background-color 0.3s;
  }
  
  .scrolled {
    background-color: #333;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    color: #fff;
  }
  
  .logo img {
    width: 100px;
  }

  /* .button a {
    border: none;
    background-color: transparent;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    transition: all .2s ease;
    font-family: 'RobotoMonoThin', monospace;
    text-decoration: none;
  }


  .header .button a.active {
    text-decoration: underline;
    text-underline-offset: 0.9em;
    text-decoration-skip-ink: none;
    font-weight: bold;
    color: #ffffff;
  } */

  .button{
    display: flex;
    gap: 20px;
  }

  .header .button a {
    background-image: linear-gradient(
      to right,
      #54b3d6,
      #54b3d6 50%,
      #ffffff 50%
    );
    font-family: 'RobotoMonoThin', monospace;
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  
  .header .button a:before{
    content: '';
    background: #54b3d6;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
  }
  
  .header .button a:hover {
   background-position: 0;
  }
  
  .header .button a:hover::before{
    width: 100%;
  }
  
