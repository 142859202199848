@font-face {
  font-family: 'RobotoMonoRegular';
  src: url('./font/RobotoMono-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'RobotoMonoBold';
  src: url('./font/RobotoMono-Bold.ttf') format('ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMonoMedium';
  src: url('./font/RobotoMono-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMonoThin';
  src: url('./font/RobotoMono-Thin.ttf') format('ttf');
  font-weight: 100;
  font-style: normal;
}


*{
  margin: 0;
  padding: 0;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'RobotoMonoBold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  background-image: url('https://assets.website-files.com/624f34ee3b91afefdf14076f/624f8a14314de6afdb6b32c0_image-mesh-hero-blockchain-template.svg'),
                    url('./images//giphy.gif'); 
  background-repeat: no-repeat, no-repeat;
  background-position: center, center;
  background-size: 100%, cover;
}

/* Затемнення фону */
body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.821); /* Змініть кольори та прозорість за потребою */
}

.background-img{
  position: absolute;
  width: 110%;
  z-index: -1;
}

.position-absolute.bg-circle.hero-v1{
  width: 20%;
  position: absolute;
  left: -5%;
  top: -40%;
  right: auto;
  bottom: auto;
  background-image: linear-gradient(135deg, #47414a, rgba(71, 65, 74, 0));
  z-index: 1;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #333;
}
